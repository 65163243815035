import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class NgxLoaderService {
    config: NgxUiLoaderConfig;

    /**
     * Constructor
     * @param ngxUiLoaderService
     */
    constructor(private ngxUiLoaderService: NgxUiLoaderService) {
        this.config = this.ngxUiLoaderService.getDefaultConfig();
    }

    // fgSlideChange(checked: boolean, delay: number, taskId: string = 'default') {
    //     if (checked) {
    //         this.ngxUiLoaderService.startLoader('', taskId);
    //     }
    // }
}