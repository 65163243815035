import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
// import { Logger } from '@nsalaun/ng-logger';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService, 
    public router: Router
  ) {}
  canActivate(): boolean {
    console.log("In AuthGuardService")
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }else{
      if(this.auth.isCustodianAuthenticated()){
        this.router.navigate(['dashboard']);
      }
    }
    return true;
  }
}