import {  Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import { environment } from '../../environments/environment';


@Injectable()
export class Config {

    private config: Object = null;

    constructor() {
        this.config = environment;
        // console.log(this.config);
    }

    public getConfig(key: any) {
        return  this.config[key] ;
        // return this.config[key];
    }

}
