import { of as observableOf, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import * as oauthSignature from "oauth-signature";
import { Md5 } from "ts-md5/dist/md5";
import { SocketService } from "../../shared/services/socket.service";
import { CookieService } from "ngx-cookie-service";

import { Config } from "../../config/config";
import { LocalStorage } from "../libs/localstorage";
interface Intellisense {
  term?: string;
  TaskType?: string;
}
@Injectable()
export class MainService {
  url: string;
  get_hdr;
  constructor(
    private http: HttpClient,
    private config: Config,
    private router: Router,
    private localStorage: LocalStorage,
    private socketService: SocketService,
    private cookieService: CookieService
  ) {
    // this.config.getConfig('apiUrl') = "http://localhost:3000";
    // console.log(this.config.getConfig('apiUrl'));
    console.log(this.localStorage.get("token"));
    this.get_hdr = function () {
      return {
        headers: {
          Authorization: "Bearer " + this.localStorage.get("token"),
        },
        // headers: new HttpHeaders({
        //   Authorization: "Bearer " + this.localStorage.get("token"),
        // }),
        withCredentials: true,
      };
    };
  }

  login(data) {
    console.log("Dashboard api: ", this.get_hdr());
    // this.http.post(this.config.getConfig('apiUrl'), data, this.get_hdr());
    return this.http.post(
      this.config.getConfig("apiUrl") + "/api/login",
      data,
      this.get_hdr()
    );
  }

  authorize(data) {
    return this.http.post(
      this.config.getConfig("apiUrl") + "/api/postAuth",
      data,
      this.get_hdr()
    );
  }
  stocksplit(data) {
    return this.http.post(
      this.config.getConfig("apiUrl") + "/api/stockSplit",
      data,
      this.get_hdr()
    );
  }
  logout(): Observable<any> {
    this.socketService.stopTickers();
    this.router.navigate(["login"]);
    this.localStorage.remove("token");
    this.localStorage.remove("custodianAuthenticated");
    this.localStorage.remove("_id");
    this.cookieService.deleteAll();
    return this.http.get(
      this.config.getConfig("apiUrl") + "/api/logout",
      this.get_hdr()
    );
  }

  getLogs(data): Observable<any> {
    return this.http.post(
      this.config.getConfig("apiUrl") + "/api/get_logs",
      data,
      this.get_hdr()
    );
  }
  getOrderLogs(data): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/logs/orders";
    return this.http.post(url, data, this.get_hdr());
  }

  // signinUser(formData: Object): Observable<any> {

  // var url = this.getUrl(true)+"/oauth/request_token",
  //   this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"api/user/login";

  //   return this.http.post(this.config.getConfig('apiUrl'), formData);
  // }

  // getUrl(authorization) {

  //   if(this.configuration.useSandbox && !authorization){
  //     return "https://etwssandbox.com";
  //   }else{
  //     return "https://etws.com";
  //   }
  // }
  // requestToken1():Observable<any> {
  //   let timestamp = new Date().getTime().toString().substring(0,10);
  //   let nonce = Md5.hashStr(timestamp+"");
  //   var httpMethod = 'GET',
  //     url = this.getUrl(true)+"/oauth/request_token",
  //     parameters = {
  //         oauth_consumer_key : this.configuration.key,
  //         oauth_nonce : nonce,
  //         oauth_timestamp : timestamp,
  //         oauth_signature_method : 'HMAC-SHA1',
  //         oauth_version : '1.0',
  //         oauth_callback : 'oob'
  //     },
  //     consumerSecret = this.configuration.secret,
  //     encodedSignature = oauthSignature.generate(httpMethod, url, parameters, consumerSecret),
  //     signature = oauthSignature.generate(httpMethod, url, parameters, consumerSecret,
  //         { encodeSignature: false});

  //   this.config.getConfig('apiUrl') =this.getUrl(true)+"/oauth/request_token?";
  //   this.config.getConfig('apiUrl') +="oauth_consumer_key="+this.configuration.key;
  //   this.config.getConfig('apiUrl') +="&oauth_nonce="+Md5.hashStr(timestamp+"");
  //   this.config.getConfig('apiUrl') +="&oauth_signature_method=HMAC-SHA1";
  //   this.config.getConfig('apiUrl') +="&oauth_timestamp="+timestamp;
  //   this.config.getConfig('apiUrl') +="&oauth_version=1.0";
  //   this.config.getConfig('apiUrl') +="&oauth_callback=oob";
  //   this.config.getConfig('apiUrl') +="&oauth_signature="+encodedSignature;

  //   return this.http.get(this.config.getConfig('apiUrl'));
  // }

  requestToken(): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_request_token";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"&id="+id;
    return this.http.get(url, this.get_hdr());
  }
  // authorizeApplication(): Observable<any> {
  //   this.config.getConfig('apiUrl') = "http://localhost:3000/api/request_token";
  //   // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
  //   // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"&id="+id;
  //   return this.http.get(this.config.getConfig('apiUrl'));
  // }

  authorizeApplication(data): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_access_token";
    // console.log(url);
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"api/deviceitem/add";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
    return this.http.post(url, data, this.get_hdr());
  }
  // listAccounts1(oauth_token, oauth_token_secret): Observable<any> {
  //   console.log(oauth_token, oauth_token_secret);
  //   let timestamp = new Date().getTime().toString().substring(0,10);
  // let nonce = Md5.hashStr(timestamp+"")+"";
  // var httpMethod = 'GET',
  //   url = this.getUrl(false)+"/accounts/sandbox/rest/accountlist",
  //   parameters = {
  //       oauth_consumer_key : this.configuration.key,
  //       oauth_token:oauth_token,
  //       oauth_token_secret:oauth_token_secret,
  //       oauth_nonce : nonce,
  //       oauth_timestamp : timestamp,
  //       oauth_signature_method : 'HMAC-SHA1',
  //       oauth_version : '1.0',
  //       oauth_callback : 'oob'
  //   },
  //   consumerSecret = this.configuration.secret,
  //   encodedSignature = oauthSignature.generate(httpMethod, url, parameters, consumerSecret),
  //   signature = oauthSignature.generate(httpMethod, url, parameters, consumerSecret,
  //       { encodeSignature: false});

  // this.config.getConfig('apiUrl') =this.getUrl(false)+"/accounts/sandbox/rest/accountlist.json";
  // console.log(this.config.getConfig('apiUrl'));
  // // this.config.getConfig('apiUrl') +="oauth_consumer_key="+this.configuration.key;
  // // this.config.getConfig('apiUrl') +="&oauth_nonce="+Md5.hashStr(timestamp+"");
  // // this.config.getConfig('apiUrl') +="&oauth_signature_method=HMAC-SHA1";
  // // this.config.getConfig('apiUrl') +="&oauth_timestamp="+timestamp;
  // // this.config.getConfig('apiUrl') +="&oauth_version=1.0";
  // // this.config.getConfig('apiUrl') +="&oauth_callback=oob";
  // // this.config.getConfig('apiUrl') +="&oauth_signature="+encodedSignature;
  // let hdr = {
  //   headers: new HttpHeaders({
  //     'Authorization': 'oauth-1.0a',
  //     'oauth_consumer_key': parameters.oauth_consumer_key,
  //     'oauth_token': parameters.oauth_token,
  //     'oauth_signature_method': parameters.oauth_signature_method,
  //     'oauth_timestamp': parameters.oauth_timestamp,
  //     'oauth_nonce':parameters.oauth_nonce,
  //     'oauth_version': parameters.oauth_version,
  //     'signature': encodedSignature
  //   })
  // };
  // console.log(hdr);
  // return this.http.get(this.config.getConfig('apiUrl'),hdr );
  // }
  listAccounts(oauth_token, oauth_token_secret): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_accounts";
    return this.http.get(url, this.get_hdr());
  }
  lookUpProducts(data, oauth_token, oauth_token_secret): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/look_up_product";
    return this.http.post(url, data, this.get_hdr());
  }
  getQuote1(data): Observable<any> {
    let param = {
      symbol: data,
    };
    var url = this.config.getConfig("apiUrl") + "/api/get_quote";
    return this.http.post(url, param, this.get_hdr());
  }

  getQuote(term: string) {
    // console.log(term);
    if (term === "") {
      return observableOf([]);
    }
    let param = {
      symbol: term,
    };
    var url = this.config.getConfig("apiUrl") + "/api/get_quote";
    return this.http.post(url, param, this.get_hdr()).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }

  getAccountPositions(data, oauth_token, oauth_token_secret): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_account_position";
    url = url + "?&oauth_token=" + oauth_token;
    url = url + "&oauth_token_secret=" + oauth_token_secret;
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"api/deviceitem/add";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
    return this.http.post(url, data);
  }
  get_portfolio(data, oauth_token, oauth_token_secret): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_portfolio";
    url = url + "?&oauth_token=" + oauth_token;
    url = url + "&oauth_token_secret=" + oauth_token_secret;
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"api/deviceitem/add";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
    return this.http.post(url, data, this.get_hdr());
  }
  get_portfolios(): Observable<any> {
    console.log("Dashboard api: ", this.get_hdr());
    // var url = this.config.getConfig('apiUrl')+"/abc";
    var url = this.config.getConfig("apiUrl") + "/api/dashboard";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"api/deviceitem/add";
    // this.config.getConfig('apiUrl') = this.config.getConfig('apiUrl')+"?token="+this.getToken();
    return this.http.get(url, this.get_hdr());
  }

  get_rsi_90_data(): Observable<any> {
    console.log("Dashboard api: ", this.get_hdr());
    var url = this.config.getConfig("apiUrl") + "/api/get_rsi_90_data";
    return this.http.get(url, this.get_hdr());
  }

  update_rsi_90_data(param): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/update_rsi_90_data";
    return this.http.post(url, param, this.get_hdr());
  }

  updateProfile(param) {
    var url = this.config.getConfig("apiUrl") + "/api/profile/update";
    return this.http.post(url, param, this.get_hdr());
  }

  getProfile(): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/profile/get";
    console.log("the token is >>>", this.get_hdr());
    return this.http.get(url, this.get_hdr());
  }
  getAccounts(): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/get_accounts";
    return this.http.get(url, this.get_hdr());
  }
  update_stop_loss(param): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/update_stop_loss";
    return this.http.post(url, param, this.get_hdr());
  }

  update_stop_loss_value(param): Observable<any> {
    var url = this.config.getConfig("apiUrl") + "/api/update_stop_loss_value";
    return this.http.post(url, param, this.get_hdr());
  }

  update_account(param) {
    var url = this.config.getConfig("apiUrl") + "/api/accounts/update";
    return this.http.post(url, param, this.get_hdr());
  }
  update_dashboard(param) {
    var url = this.config.getConfig("apiUrl") + "/api/dashboard/update";
    return this.http.post(url, param, this.get_hdr());
  }
  update_liquidate_before(param) {
    var url =
      this.config.getConfig("apiUrl") + "/api/dashboard/setLiquidatePercent";
    return this.http.post(url, param, this.get_hdr());
  }
  liquidate_long(param) {
    var url = this.config.getConfig("apiUrl") + "/api/dashboard/liquidateLong";
    return this.http.post(url, param, this.get_hdr());
  }
  liquidate_short(param) {
    var url = this.config.getConfig("apiUrl") + "/api/dashboard/liquidateShort";
    return this.http.post(url, param, this.get_hdr());
  }
  remove_buy_or_sell(param) {
    var url = this.config.getConfig("apiUrl") + "/api/dashboard/removeBuyOrSell";
    return this.http.post(url, param, this.get_hdr());
  }
  update_account_tickers_settings(param) {
    var url =
      this.config.getConfig("apiUrl") + "/api/dashboard/update/account_tickers";
    return this.http.post(url, param, this.get_hdr());
  }
  add_portfolio(param) {
    delete param.id;
    var url = this.config.getConfig("apiUrl") + "/api/portfolio/add";
    return this.http.post(url, param, this.get_hdr());
  }
  get_instruments() {
    var url =
      this.config.getConfig("apiUrl") + "/api/instruments/getInstruments";
    return this.http.get(url, this.get_hdr());
  }
  delete_instruments(id) {
    let obj = {
      id: id,
    };
    var url =
      this.config.getConfig("apiUrl") + "/api/instruments/deleteInstrument";
    return this.http.post(url, obj, this.get_hdr());
  }
  get_search_instruments(data) {
    let val = data.toUpperCase();
    let obj = {
      symbol: val,
    };
    var url =
      this.config.getConfig("apiUrl") + "/api/instruments/getInstrumentFromTDA";
    // return this.http.post(url,obj, this.get_hdr());
    return this.http.post(url, obj, this.get_hdr()).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }
  add_instruments(data) {
    var url = this.config.getConfig("apiUrl") + "/api/instruments/post";
    return this.http.post(url, data, this.get_hdr());
  }
  update_ticker_order(data) {
    var url = this.config.getConfig("apiUrl") + "/api/dashboard/updateOrder";
    return this.http.post(url, data, this.get_hdr());
  }
}
