import { Component, ChangeDetectorRef, Input  } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../services/main.service';
// import { SocketService } from '../services/socket.service';
import { LocalStorage } from '../libs/localstorage';
import { SocketService } from '../services/socket.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent {

    reminderNotifications: any = [];
    reminderCount: number = 0;
    intervalId;
	@Input() rsi90: number;
	@Input() vixCurrent: number;
	@Input() turnOn: boolean;

    constructor (
        public router: Router,
        private mainServices: MainService,
		private toastrService: ToastrService,
		public cdr: ChangeDetectorRef,
		private loaderService: NgxUiLoaderService) {
    }

    ngOnInit() {
	    this.loaderService.start();
		if(!this.turnOn){
			this.getProfile()
		}
	    setTimeout(() => this.loaderService.stop(), 3000);
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        // this.socketService.disconnectSocket();
    }

	changeStatus(value) {
		this.loaderService.start();
		let status = {
			"turnOn": (value) ? "1" : "0"
		}
		this.mainServices.updateProfile(status).subscribe(
			res => {
				if (res && res['success']) {
					this.turnOn = !this.turnOn;
				}
			}, err => {
				if (err.error.authenticated == false) {
					this.mainServices.logout().subscribe();
				}
				if(err.error.message){
					this.toastrService.error(err['error']['message'], "Error");
				} else{
					this.toastrService.error('Unable to get response from Our Lira servers', "Error");
				}
			}
		)
		setTimeout(() => this.loaderService.stop(), 3000);
	}

    logout() {
      this.mainServices.logout().subscribe();
	}
    show_dashboard(){
        this.router.navigate(['dashboard']);
    }

	getProfile() {
		this.mainServices.getProfile().subscribe(
			res => {
				if (res && res['success']) {
					this.turnOn = res['data'].turnOn;
					this.cdr.detectChanges();
				}
				else {
					this.toastrService.error(res['message'], "Error");
				}
			}, err => {
				if (err.error.authenticated == false) {
					this.mainServices.logout().subscribe();
				}
				if(err.error.message){
					this.toastrService.error(err['error']['message'], "Error");
				} else{
					this.toastrService.error('Unable to get response from Our Lira servers', "Error");
				}
			}
		);
	}

}
