import { Component, OnInit, NgZone, Input } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
// import { SocketService } from '../../services/socket.service';
import { NgxUiLoaderService, Loader } from "ngx-ui-loader";
import { MainService } from "../../services/main.service";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  // providers:[SocketService]
})
export class ProfileComponent implements OnInit {
  // ------Ngx-Data table--
  button_disabled: boolean = false;
  form: FormGroup;
  view_password_field: boolean = false;
  edit_profile: boolean = false;
  @Input() user;
  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loaderService: NgxUiLoaderService
  ) {
    // console.log("user", this.user);
    this.form = this.fb.group({
      fullName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
      changePassword: [false],
      editProfile: [false],
    });
  }

  toggleEditProfile() {
    this.form.get("editProfile").setValue(!this.form.get("editProfile").value);
  }
  togglePassword() {
    // console.log(this.form);
    this.form
      .get("changePassword")
      .setValue(!this.form.get("changePassword").value);
  }
  getProfile() {
    this.mainService.getProfile().subscribe(res => {
      this.form.controls["fullName"].setValue(res["data"]["fullName"]);
      this.form.controls["email"].setValue(res["data"]["email"]);
    })
  }
  ngOnInit() {
    this.getProfile()
  }
  ngOnChanges() {
    // console.log("Changes");
    // if (this.user) {
    //   this.form.controls["fullName"].setValue(this.user["fullName"]);
    //   this.form.controls["email"].setValue(this.user["email"]);
    //   // this.form = this.fb.group({
    //   //   "fullName": [this.user['fullName'], [Validators.required]],
    //   //   "email": [this.user['email'], [Validators.required]],
    //   // });
    // }
  }

  // show_password_field(){
  //   if(!this.form.get("changePassword").value){
  //     const form = this.fb.group({
  //       "fullName": [this.form.controls['fullName'].value, [Validators.required]],
  //       "email": [this.form.controls['email'].value, [Validators.required]],
  //       "password": ['', [Validators.required]],
  //       "confirmPassword": ['', [Validators.required]],
  //       "changePassword":[true],
  //       "editProfile":[false],
  //     });
  //     this.form = form;
  //     this.view_password_field = true;
  //   }else{
  //     const form = this.fb.group({
  //       "fullName": [this.form.controls['fullName'].value, [Validators.required]],
  //       "email": [this.form.controls['email'].value, [Validators.required]],
  //     });
  //     this.form = form;
  //     this.view_password_field = false;
  //   }
  // }
  // populateForm(){

  // }
  cancel(personalInfo: boolean, formControl) {
    if (personalInfo) {
      this.getProfile()
    }
    formControl.setValue(false);
  }
  saveProfile(formControl) {
    this.loaderService.start();
    formControl.setValue(false);
    let param = {
      fullName: this.form.controls["fullName"].value,
      email: this.form.controls["email"].value,
    };
    this.mainService.updateProfile(param).subscribe(
      (res) => {
        if (res && res["success"]) {
          this.form.controls["fullName"].setValue(res["data"]["fullName"]);
          this.form.controls["email"].setValue(res["data"]["email"]);
          this.toastrService.success(res["message"], "Success");
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
          // this.liraService.disconnectSocket();
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
    setTimeout(() => this.loaderService.stop(), 2000);
  }
  onSubmit(formControl) {
    this.loaderService.start();
    formControl.setValue(false);
    let param = {
      password: this.form.controls["password"].value,
    };
    this.mainService.updateProfile(param).subscribe(
      (res) => {
        this.form.controls["password"].setValue("");
        this.form.controls["confirmPassword"].setValue("");
        if (res && res["success"]) {
          this.form.controls["fullName"].setValue(res["data"]["fullName"]);
          this.form.controls["email"].setValue(res["data"]["email"]);
          this.toastrService.success(res["message"], "Success");
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        this.form.controls["password"].setValue("");
        this.form.controls["confirmPassword"].setValue("");
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
          // this.liraService.disconnectSocket();
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
    setTimeout(() => this.loaderService.stop(), 2000);
  }

  // onSubmit() {
  // 	this.button_disabled=true;
  // 	if(this.form.valid) {
  //     let param;
  //     if(this.view_password_field){
  //       param = {
  //           // 'fullName':this.form.controls['fullName'].value,
  //           // 'email':this.form.controls['email'].value,
  //           'password':this.form.controls['password'].value,
  //       };
  //     }else{
  //       param = {
  //           'fullName':this.form.controls['fullName'].value,
  //           'email':this.form.controls['email'].value,
  //       };
  //     }
  // 		this.mainService.updateProfile(param).subscribe(
  // 			res => {
  //         this.button_disabled=false;
  // 				if(res && res['success']) {
  // 					this.toastrService.success(res['message'], "Success");
  //           this.router.navigate(['dashboard']);
  //           // this.router.navigate(['list'], { relativeTo: this.route.parent });
  //         }
  //         else {
  //           this.toastrService.error(res['message'], "Error");
  //         }
  //       },err=>{
  //         if(err.error.authenticated==false){
  //           this.mainService.logout();
  //           // this.liraService.disconnectSocket();
  //         }
  //         this.toastrService.error(err['error']['message'], "Error");
  //     }
  //     );
  //   }
  // 	else {
  // 		this.toastrService.error("Please try again! Something went wrong", "Error");
  // 	}
  // }
}
