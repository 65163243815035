import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
// import { SocketService } from './shared/services/socket.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from "./shared/services/main.service";
import { NgxLoaderService } from './shared/services/ui-loader.service';

import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  @ViewChild('invalidToken', {static:false}) invalidTokenModal: ModalDirective;
  form: FormGroup;

  link: string;
  constructor(
    private router: Router,
    // private socketService: SocketService,
    private mainService: MainService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    public loaderService: NgxLoaderService) {
    this.form = this.fb.group({
      "verificationCode": ['', [Validators.required]],
      // "password":["", [Validators.required]]
    });
  }


  authorizeApplication() {
    // let param = {
    //   'verificationCode':this.form.controls['verificationCode'].value,
    // };
    // this.mainService.authorizeApplication(param).subscribe(
    //   res=>{
    //     if(res.status){
    //         this.form.reset();
    //         localStorage.setItem('oauth_token', res.data.oauth_token);
    //         localStorage.setItem('oauth_token_secret', res.data.oauth_token_secret);
    //         //this.router.navigate(['dashboard']);
    //         this.invalidTokenModal.hide();
    //         this.socketService.emitJoin();
    //         this.toastrService.success(res['message'], "Success");
    //       }else{
    //         this.toastrService.error(res['message'], "Error");
    //       }
    //   }
    // );
  }
}
