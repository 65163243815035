import { Component, OnInit, NgZone, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
// import { SocketService } from '../../services/socket.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { MainService } from '../../services/main.service';
@Component({
  selector: 'accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  // providers:[SocketService]
})
export class AccountsComponent implements OnInit {
  data = [];
  form: FormGroup;
  @Input() user;
  commissionEdit: Boolean;
  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loaderService: NgxUiLoaderService) {
  }

  ngOnInit() {
    this.getAccounts();
    this.form = this.fb.group({
      "commission": [(this.user && this.user.commission && this.user.commission>=0) ? ("$ "+((Math.round((this.user.commission) * 100) / 100).toFixed(2))) : "$ 0.00", [Validators.required]],
      "sellCommission": [(this.user && this.user.sellCommission && this.user.sellCommission>=0) ? ("$ "+((Math.round((this.user.sellCommission) * 100) / 100).toFixed(2))) : "$ 0.00", [Validators.required]],
    });
  }
  ngOnChanges() {

    if (this.user) {
      this.form = this.fb.group({
        "commission": ["$ "+((Math.round((this.user['commission']) * 100) / 100).toFixed(2)), [Validators.required]],
        "sellCommission": ["$ "+((Math.round((this.user['sellCommission']) * 100) / 100).toFixed(2)), [Validators.required]],
      });
    }
  }
  onSubmit() {
    this.loaderService.start();

    let commissionOnSubmit = this.form.controls['commission'].value;
    let sellCommissionOnSubmit = this.form.controls['sellCommission'].value;

    if(!commissionOnSubmit ||(commissionOnSubmit < 0)){
      this.form.controls['commission'].setValue(0);
    }
    if(!sellCommissionOnSubmit ||(sellCommissionOnSubmit < 0)){
      this.form.controls['sellCommission'].setValue(0);
    }
    if(commissionOnSubmit != this.user.commission || sellCommissionOnSubmit != this.user.sellCommission){
      let param = {
        'commission': (commissionOnSubmit),
        'sellCommission': (sellCommissionOnSubmit),
      };
      this.mainService.updateProfile(param).subscribe(
        res => {
          if (res && res['success']) {
            this.user.commission = this.form.controls['commission'].value;
            this.user.sellCommission = this.form.controls['sellCommission'].value;
            this.commissionEdit = false;
            ((Math.round((this.user.commission) * 100) / 100).toFixed(2))
            // this.form.controls['commission'].setValue("$ "+this.user.commission);
            this.form.controls['commission'].setValue("$ "+((Math.round((this.user.commission) * 100) / 100).toFixed(2)));
            this.form.controls['sellCommission'].setValue("$ "+((Math.round((this.user.sellCommission) * 100) / 100).toFixed(2)));
            this.toastrService.success(res['message'], "Success");
          }
          else {
            this.toastrService.error(res['message'], "Error");
          }
        }, err => {
          if (err.error.authenticated == false) {
            this.mainService.logout().subscribe(
              res=>{
              //   if(res['status']){
                  // }
              },
              err => {
              });
            // this.liraService.disconnectSocket();
          }
          if(err.error.message){
            this.toastrService.error(err['error']['message'], "Error");
          } else{
            this.toastrService.error('Unable to get response from Our Lira servers', "Error");
          }
        }
      );
      setTimeout(() => this.loaderService.stop(), 3000);
    } else{
      this.commissionEdit = false;

      // this.form.controls['commission'].setValue("$ "+this.form.controls['commission'].value);
      this.form.controls['commission'].setValue("$ "+((Math.round((this.form.controls['commission'].value) * 100) / 100).toFixed(2)));
      this.form.controls['sellCommission'].setValue("$ "+((Math.round((this.form.controls['sellCommission'].value) * 100) / 100).toFixed(2)));
      this.loaderService.stop()
    }
  }
  getAccounts() {
    this.mainService.getAccounts().subscribe(
      res => {
        // console.log(res);
        if (res && res['success']) {
          this.data = res['data']['accounts'];
          for (let index in this.data) {
            this.data[index]['edit'] = false;
            this.data[index]['new_cash_reserve'] = this.data[index]['cash_reserve'];
          }
        }
        else {
          this.toastrService.error(res['message'], "Error");
        }
      }, err => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            res=>{
            //   if(res['status']){
                // }
            },
            err => {
            });
          // this.liraService.disconnectSocket();
        }
        if(err.error.message){
          this.toastrService.error(err['error']['message'], "Error");
        } else{
          this.toastrService.error('Unable to get response from Our Lira servers', "Error");
        }
      }
    );
  }
  changeCommissionBoolean(value) {
    this.commissionEdit = value;
    if(value){
      this.form.controls['commission'].setValue(this.user.commission);
      this.form.controls['sellCommission'].setValue(this.user.sellCommission);
    }else{
      this.form.controls['commission'].setValue("$ "+this.user.commission);
      this.form.controls['sellCommission'].setValue("$ "+this.user.sellCommission);
    }
  }

  onNumberChange(rowIndex) {
    let min = 0;
    // netAccountValue
    // console.log(this.data[rowIndex]);
    if (this.data[rowIndex].new_cash_reserve < min) {
      this.data[rowIndex].new_cash_reserve = min;
    }
    // if (this.data[rowIndex].new_cash_reserve > this.data[rowIndex].netAccountValue) {
    //   this.data[rowIndex].new_cash_reserve = this.data[rowIndex].netAccountValue;
    // }
  }
  edit(account) {
    account.edit = true;
  }
  save(account) {
    // console.log(account);
    let param = {
      _id: account.id,
      cash_reserve: account.new_cash_reserve
    };

    this.mainService.update_account(param).subscribe(
      res => {
        if (res && res['success']) {
          this.data = res['data']['accounts'];
          for (let index in this.data) {
            this.data[index]['edit'] = false;
            this.data[index]['new_cash_reserve'] = this.data[index]['cash_reserve'];
          }
        }
        else {
          this.toastrService.error(res['message'], "Error");
        }
      }, err => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            res=>{
            //   if(res['status']){
                // }
            },
            err => {
            });
          // this.liraService.disconnectSocket();
        }
        if(err.error.message){
          this.toastrService.error(err['error']['message'], "Error");
        } else{
          this.toastrService.error('Unable to get response from Our Lira servers', "Error");
        }
      }
    );

  }
  cancel(account) {
    account.edit = false;
    account.new_cash_reserve = account.cash_reserve;
  }
}
