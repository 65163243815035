import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
// import { Logger } from '@nsalaun/ng-logger';
@Injectable()
export class CustodianAuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    // public logger: Logger
  ) {}
  canActivate(): boolean {
    console.log("In CustodianAuthGuardService")
    if (this.auth.isAuthenticated()) {
      // console.log("token exists");
      if(!this.auth.isCustodianAuthenticated()){
        console.log('Custodian not authenticated. Navigating to authorization flow.');
        this.router.navigate(['login/authorization']);
        // this.router.navigate(['login/authorization/']);
        return false;
      }
    }else{
      this.router.navigate(['login']);
      return false;
    }
    // console.log("user can access this page");
    return true;
  }
}
