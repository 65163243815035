import {
  Component,
  OnInit,
  NgZone,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SocketService } from "../../services/socket.service";
import { NgbdModalContent } from "../../modals/alertModal/alertModal-component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Page } from "../../../orderlogs/model/page";
import { NgxUiLoaderService, Loader } from "ngx-ui-loader";
import { NgxCoolDialogsService } from "ngx-cool-dialogs";
import { MainService } from "../../services/main.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
@Component({
  selector: "portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
  // providers:[SocketService]
})
export class PortfolioComponent implements OnInit {
  page = new Page();
  data = [];
  numbers = [];
  form: FormGroup;
  @Input() user;
  allocationEdit: Boolean;
  allocationEditRsi: Boolean;
  rsi90FormEdit = false;
  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mainService: MainService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private toastrService: ToastrService,
    private confirmation: NgxCoolDialogsService,
    private loaderService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef
  ) {
    this.socketService.getDashboard();
    this.page.pageNumber = 1;
    this.page.size = 20;
    this.numbers = [].fill(19, 0, 20);
  }

  ngOnInit() {
    this.getAccounts();
    this.form = this.fb.group({
      shortAllocation: [
        this.user && this.user.shortAllocation && this.user.shortAllocation >= 0
          ? this.user.shortAllocation.toFixed(2) + " %"
          : "0.00 %",
        [Validators.required],
      ],
      maximumSecurityAllocation: [
        this.user &&
        this.user.maximumSecurityAllocation &&
        this.user.maximumSecurityAllocation >= 0
          ? this.user.maximumSecurityAllocation.toFixed(2) + " %"
          : "0.00 %",
        [Validators.required],
      ],
      purchaseLong: [
        this.user && this.user.purchaseLong && this.user.purchaseLong >= 0
          ? (Math.round(this.user.purchaseLong * 100) / 100).toFixed(2) + " %"
          : "0.00 %",
        [Validators.required],
      ],
      purchaseShort: [
        this.user && this.user.purchaseShort && this.user.purchaseShort >= 0
          ? (Math.round(this.user.purchaseShort * 100) / 100).toFixed(2) + " %"
          : "0.00 %",
        [Validators.required],
      ],
      buyInterval: [this.user.buyInterval, [Validators.required]],
      sellInterval: [this.user.sellInterval, [Validators.required]],
      support: [this.user.support, [Validators.required]],
      resistance: [this.user.resistance, [Validators.required]],
      rsi90: [
        this.user && this.user.rsi90 && this.user.rsi90 >= 0
          ? (Math.round(this.user.rsi90 * 100) / 100).toFixed(2)
          : "0.00",
        [Validators.required],
      ],
      rsi90Mid: [
        this.user && this.user.rsi90Mid && this.user.rsi90Mid >= 0
          ? (Math.round(this.user.rsi90Mid * 100) / 100).toFixed(2)
          : "0.00",
        [Validators.required],
      ],
      rsi90Low: [
        this.user && this.user.rsi90Low && this.user.rsi90Low >= 0
          ? this.user.rsi90Low + " %"
          : 0 + " %",
        [Validators.required],
      ],
      rsi90High: [
        this.user && this.user.rsi90High && this.user.rsi90High >= 0
          ? this.user.rsi90High + " %"
          : 0 + " %",
        [Validators.required],
      ],
      vix: [
        this.user && this.user.vix && this.user.vix >= 0
          ? (Math.round(this.user.vix * 100) / 100).toFixed(2)
          : "0.00",
        [Validators.required],
      ],
      vixCurrent: [
        this.user && this.user.vixCurrent && this.user.vixCurrent >= 0
          ? (Math.round(this.user.vixCurrent * 100) / 100).toFixed(2)
          : "0.00",
        [Validators.required],
      ],
    });
  }

  get rsi90DataRows(): FormArray {
    return this.rsi90Form.get('rsi90DataRows') as FormArray;
  }

  private createRSI90FormGroup(data: any): FormGroup {
    return this.fb.group({
      week: [data.week, Validators.required],
      value: [data.value.toFixed(2), Validators.required],
      startDate: [data.startDate, Validators.required],
      endDate: [data.endDate, Validators.required]
    });
  }

  ngOnChanges() {
    if (this.user) {
      this.form = this.fb.group({
        shortAllocation: [
          this.user &&
          this.user.shortAllocation &&
          this.user.shortAllocation >= 0
            ? this.user.shortAllocation.toFixed(2) + " %"
            : "0.00 %",
          [Validators.required],
        ],
        maximumSecurityAllocation: [
          this.user &&
          this.user.maximumSecurityAllocation &&
          this.user.maximumSecurityAllocation >= 0
            ? this.user.maximumSecurityAllocation.toFixed(2) + " %"
            : "0.00 %",
          [Validators.required],
        ],
        purchaseLong: [
          this.user && this.user.purchaseLong && this.user.purchaseLong >= 0
            ? (Math.round(this.user.purchaseLong * 100) / 100).toFixed(2) + " %"
            : "0.00 %",
          [Validators.required],
        ],
        purchaseShort: [
          this.user && this.user.purchaseShort && this.user.purchaseShort >= 0
            ? (Math.round(this.user.purchaseShort * 100) / 100).toFixed(2) +
              " %"
            : "0.00 %",
          [Validators.required],
        ],
        buyInterval: [this.user.buyInterval, [Validators.required]],
        sellInterval: [this.user.sellInterval, [Validators.required]],
        support: [this.user.support, [Validators.required]],
        resistance: [this.user.resistance, [Validators.required]],
        rsi90: [
          this.user && this.user.rsi90 && this.user.rsi90 >= 0
            ? (Math.round(this.user.rsi90 * 100) / 100).toFixed(2)
            : "0.00",
          [Validators.required],
        ],
        rsi90Mid: [
          this.user && this.user.rsi90Mid && this.user.rsi90Mid >= 0
            ? (Math.round(this.user.rsi90Mid * 100) / 100).toFixed(2)
            : "0.00",
          [Validators.required],
        ],
        rsi90Low: [
          this.user && this.user.rsi90Low && this.user.rsi90Low >= 0
            ? this.user.rsi90Low + " %"
            : 0 + " %",
          [Validators.required],
        ],
        rsi90High: [
          this.user && this.user.rsi90High && this.user.rsi90High >= 0
            ? this.user.rsi90High + " %"
            : 0 + " %",
          [Validators.required],
        ],
        vix: [
          this.user && this.user.vix && this.user.vix >= 0
            ? (Math.round(this.user.vix * 100) / 100).toFixed(2)
            : "0.00",
          [Validators.required],
        ],
        vixCurrent: [
          this.user && this.user.vixCurrent && this.user.vixCurrent >= 0
            ? (Math.round(this.user.vixCurrent * 100) / 100).toFixed(2)
            : "0.00",
          [Validators.required],
        ],
      });
    }
  }
  checkLimit(event) {
    if (Number(event.target.value) > 100) {
      this.form.controls[
        event.currentTarget.getAttribute("formControlName")
      ].setValue(100);
    }
    // if(Number(event.target.value) < 0){
    //   this.form.controls[event.currentTarget.getAttribute('formControlName')].setValue(0);
    // }
  }
  checkInterval(event) {
    if (
      !event.target.value.match(/^[0-9]{1,2}$/) &&
      !event.target.value.match(/^[0-9]?\d:[0-9]\d$/)
    ) {
      this.form.controls[
        event.currentTarget.getAttribute("formControlName")
      ].setValue("15:00");
    }
    // if(Number(event.target.value) > 100){
    //   this.form.controls[event.currentTarget.getAttribute('formControlName')].setValue(99);
    // }
    // if(Number(event.target.value) < 0){
    //   this.form.controls[event.currentTarget.getAttribute('formControlName')].setValue(1);
    // }
  }

  iraData: any = [];
  nonIraData: any = [];
  rsi90Form: FormGroup;

  stages: any = [];
  iraAccountId: 0;
  nonIraAccountId: 0;
  accounts: any = [];
  tickers: any = [];

  matrices: any = [];
  stockSplitTickers: any = [];
  public modalOpenCheck = false;
  public modalRef;
  isDailyReset = true;
  isRsi90 = false;
  isDashboard = false;
  isDashboardAccount = false;
  isDashboardNonIraAccount = false;
  isDashboardIraAccount = false;
  isStockSplit = false;

  isStoploss = false;
  stocksData = [];
  public rsiData;
  checkExecution() {
    for (let order of this.data) {
      if (
        order.revisedPurchaseQuantity &&
        order.revisedPurchaseQuantity != order.remain
      ) {
        return false;
      }
    }
    return true;
  }
  closeSplit: boolean = false;
  closeSplitFunc() {
    this.closeSplit = false;
    this.selectedAccount = 0;
    this.selectedTicker = 0;
  }
  executeSplit() {
    let tempData = [];
    for (let order of this.data) {
      order.user = this.user;
      if (
        order.revisedPurchaseQuantity &&
        order.revisedPurchaseQuantity != order.remain
      ) {
        tempData.push(order);
      }
    }

    this.mainService.stocksplit(tempData).subscribe(
      (res) => {
        this.closeSplit = true;
        if (res["success"]) {
          this.toastrService.success(
            "Stock split executed successfully",
            "Success"
          );
          this.getLogs(false);
        }
      },
      (err) => {
        this.closeSplit = true;
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  async toggleTabs(val) {
    this.allocationEdit = false;
    this.allocationEditRsi = false;
    this.form.controls["shortAllocation"].setValue(
      this.user.shortAllocation.toFixed(2) + " %"
    );
    this.form.controls["maximumSecurityAllocation"].setValue(
      this.user.maximumSecurityAllocation.toFixed(2) + " %"
    );
    this.form.controls["purchaseLong"].setValue(
      (Math.round(this.user.purchaseLong * 100) / 100).toFixed(2) + " %"
    );
    this.form.controls["purchaseShort"].setValue(
      (Math.round(this.user.purchaseShort * 100) / 100).toFixed(2) + " %"
    );
    this.form.controls["rsi90"].setValue(
      (Math.round(this.user.rsi90 * 100) / 100).toFixed(2)
    );
    this.form.controls["rsi90Mid"].setValue(
      (Math.round(this.user.rsi90Mid * 100) / 100).toFixed(2)
    );
    this.form.controls["rsi90Low"].setValue(this.user.rsi90Low + " %");
    this.form.controls["rsi90High"].setValue(this.user.rsi90High + " %");
    this.form.controls["vix"].setValue(
      (Math.round(this.user.vix * 100) / 100).toFixed(2)
    );
    this.form.controls["vixCurrent"].setValue(
      (Math.round(this.user.vixCurrent * 100) / 100).toFixed(2)
    );
    this.form.controls["buyInterval"].setValue(this.user.buyInterval);
    this.form.controls["sellInterval"].setValue(this.user.sellInterval);
    this.form.controls["support"].setValue(this.user.support);
    this.form.controls["resistance"].setValue(this.user.resistance);
    if (val == 0) {
      this.socketService.stopTickers();
      this.isDashboardIraAccount = false;
      this.isDashboardNonIraAccount = false;
      this.isDashboard = false;
      this.isDailyReset = false;
      this.isDailyReset = true;
      this.isRsi90 = false;
      this.isStockSplit = false;
      this.isStoploss = false;
    } else if (val == 1) {
      this.isDashboardIraAccount = false;
      this.isDashboardNonIraAccount = false;
      this.isDashboard = false;
      this.isDailyReset = false;
      this.isDailyReset = false;
      this.isRsi90 = true;
      this.isStoploss = false;
      this.isStockSplit = false;
      this.connectSocket();
      this.getRSI90Data();
    } else if (val == 2) {
      this.isDashboardIraAccount = true;
      this.isDashboardNonIraAccount = false;
      this.isDashboard = true;
      this.isDailyReset = false;
      this.isRsi90 = false;
      this.isStoploss = false;
      this.isStockSplit = false;
          console.log("Calling getPortfolios")
          this.getPortfolios();
    } else if (val == 3) {
      this.socketService.stopTickers();
      this.isDashboardIraAccount = false;
      this.isDashboardNonIraAccount = false;
      this.isDashboard = false;
      this.isDailyReset = false;
      this.isDailyReset = false;
      this.isRsi90 = false;
      this.selectedAccount = 0;
      this.selectedTicker = 0;
      this.isStoploss = false;
      this.isStockSplit = true;
          console.log("Calling getPortfolios")
          this.getPortfolios();
    } else if (val == 5) {
      this.socketService.stopTickers();
      this.isDashboardIraAccount = true;
      this.isDashboardNonIraAccount = false;
      this.isDashboard = false;
      this.isDailyReset = false;
      this.isRsi90 = false;
      this.selectedAccount = 0;
      this.selectedTicker = 0;
      this.isStoploss = true;
      this.isStockSplit = false;
          console.log("Calling getPortfolios")
          this.getPortfolios();
      // if(this.data[0] && this.data[0].registrationType =='IRA'){
      // 	this.toggleDashboardTabs(0)
      // } else{
      // 	this.toggleDashboardTabs(1)
      // }
      this.securityStoploss();
      console.log("yes => ", this.user)
      this.stoplossStage1 = parseFloat(
        this.user.stoplossStage1
      ).toFixed(2);
      this.stoplossStage3 = parseFloat(
        this.user.stoplossStage3
      ).toFixed(2);
      this.stoplossStage4 = parseFloat(
        this.user.stoplossStage4
      ).toFixed(2);
      this.maxRoiStage1 = parseFloat(
        this.user.maxRoiStage1
      ).toFixed(2);
      this.maxRoiStage2 = parseFloat(
        this.user.maxRoiStage2
      ).toFixed(2);
      this.maxRoiStage3 = parseFloat(
        this.user.maxRoiStage3
      ).toFixed(2);
      this.updateStages()
    }
  }
  selectedAccount = 0;
  selectedTicker = 0;

  updateStages(){
    this.stages = [
      {
        name: "Stage 1",
        description1: '',
        description2: "After the initial purchase, don't lose more than ",
        maxRoi: null,
        stoploss: this.stoplossStage1 ? this.stoplossStage1 : 0
      },
      {
        name: "Stage 2",
        description1: 'When my ROI reaches ',
        description2: 'I will breakeven',
        maxRoi: this.maxRoiStage1 ? this.maxRoiStage1 : 0,
        stoploss: '0.00'
      },
      {
        name: "Stage 3",
        description1: 'When my ROI reaches',
        description2: 'retain percentage of profits',
        maxRoi: this.maxRoiStage2 ? this.maxRoiStage2 : 0,
        stoploss: this.stoplossStage3 ? this.stoplossStage3 : 0
      },
      {
        name: "Stage 4",
        description1: 'When my ROI reaches',
        description2: 'subtract from the max ROI',
        maxRoi: this.maxRoiStage3 ? this.maxRoiStage3 : 0,
        stoploss: this.stoplossStage4 ? this.stoplossStage4 : 0
      }
    ]
  }

  cancelSplit() {
    this.selectedTicker = 0;
    this.selectedAccount = 0;
    this.stockSplitTickers = [];
  }
  securityStoploss() {}
  accountSelect(event) {
    this.selectedTicker = 0;
    this.selectedAccount = event.target.value;
    this.stockSplitTickers = [];
    for (let ticker of this.tickers) {
      if (ticker.remain && ticker.account.accountId == this.selectedAccount) {
        this.stockSplitTickers.push(ticker);
      }
    }
    this.stockSplitTickers.sort(function (a, b) {
      var textA = a.symbol.toUpperCase();
      var textB = b.symbol.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
  setRevisedQuantity(event, row) {
    console.log(row);
  }
  tickerSelect(event) {
    for (let ticker of this.stockSplitTickers) {
      if (
        ticker.symbol == event.target.value &&
        ticker.account.accountId == this.selectedAccount
      ) {
        this.selectedTicker = ticker.id;
      }
    }
    this.getLogs(false);
  }
  symbol = null;
  getLogs(val) {
    // console.log("asdfasd");
    this.mainService
      .getOrderLogs({
        tickerId: this.selectedTicker,
        page: this.page,
        mail: val,
      })
      .subscribe(
        (res) => {
          // console.log(res.page)
          if (res.status) {
            if (val != true) {
              this.data = res.data;
              this.page = res.page;
              for (let order of this.data) {
                // order.revisedPurchaseQuantity = order.purchaseShareQty;
              }
            } else {
              this.toastrService.success(res["message"], "Success");
            }
          }
        },
        (err) => {
          if (err.error.authenticated == false) {
            this.mainService.logout().subscribe(
              (res) => {
                //   if(res['status']){
                // }
              },
              (err) => {}
            );
            // this.liraService.disconnectSocket();
          }
          if (err.error.message) {
            this.toastrService.error(err["error"]["message"], "Error");
          } else {
            this.toastrService.error(
              "Unable to get response from Our Lira servers",
              "Error"
            );
          }
        }
      );
  }

  onChangePage($event) {
    this.page.pageNumber = $event.page;
    this.getLogs(false);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      let oldtarget = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data[event.previousIndex] =
        event.container.data[event.currentIndex];
      event.container.data[event.currentIndex] = oldtarget;
    }
  }
  dropIRA(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      let oldtarget = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data[event.previousIndex] =
        event.container.data[event.currentIndex];
      event.container.data[event.currentIndex] = oldtarget;
    }
  }

  reorderNonIraData() {
    let obj = {
      account_id: this.nonIraAccountId,
      tickers: this.nonIraData,
    };
    this.mainService.update_ticker_order(obj).subscribe(
      (res) => {
        if (res && res["success"]) {
          this.toastrService.success(res["message"], "Success");
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {},
            (err) => {}
          );
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  reorderIraData() {
    let obj = {
      account_id: this.iraAccountId,
      tickers: this.iraData,
    };
    this.mainService.update_ticker_order(obj).subscribe(
      (res) => {
        if (res && res["success"]) {
          this.toastrService.success(res["message"], "Success");
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {},
            (err) => {}
          );
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  stoplossAccount: any = {};
  stoplossStage1 = "0";
  stoplossStage3 = "0";
  stoplossStage4 = "0";
  maxRoiStage1 = "0";
  maxRoiStage2 = "0";
  maxRoiStage3 = "0";
  editStoploss = false;
  toggleStoploss(value) {
    this.editStoploss = value;
    this.stoplossStage1 = this.user.stoplossStage1;
    this.stoplossStage3 = this.user.stoplossStage3;
    this.stoplossStage4 = this.user.stoplossStage4;
    this.maxRoiStage1 = this.user.maxRoiStage1;
    this.maxRoiStage2 = this.user.maxRoiStage2;
    this.maxRoiStage3 = this.user.maxRoiStage3;
  }

  funcEditStoplossStage(stageNumber, event) {
    this['stoplossStage' + stageNumber] = event.target.value;
  }

  funcEditMaxRoiStage(stageNumber, event) {
    this['maxRoiStage' + stageNumber] = event.target.value;
  }
  

  changeStoplossValues() {
    if (parseFloat(this.stoplossStage1) > 0) {
      this.toastrService.error("Valid entries for stoploss stage 1 are < 0.00", "Error");
      return;
    }
    if (parseFloat(this.stoplossStage3) <= 0) {
      this.toastrService.error("Valid entries for stoploss stage 3 are > 0.00", "Error");
      return;
    }
    if (parseFloat(this.stoplossStage4) < 0) {
      this.toastrService.error("Valid entries for stoploss stage 4 are >= 0.00", "Error");
      return;
    }
    if (parseFloat(this.maxRoiStage1) < 0) {
      this.toastrService.error("Valid entries for max ROI stage 1 are >= 0.00", "Error");
      return;
    }
    if (parseFloat(this.maxRoiStage2) < 0) {
      this.toastrService.error("Valid entries for max ROI stage 2 are >= 0.00", "Error");
      return;
    }
    if (parseFloat(this.maxRoiStage3) < 0) {
      this.toastrService.error("Valid entries for max ROI stage 3 are >= 0.00", "Error");
      return;
    }
    this.editStoploss = false;
    this.user.stoplossStage1 = this.stoplossStage1 = parseFloat(this.stoplossStage1).toFixed(2);
    this.user.stoplossStage3 = this.stoplossStage3 = parseFloat(this.stoplossStage3).toFixed(2);
    this.user.stoplossStage4 = this.stoplossStage4 = parseFloat(this.stoplossStage4).toFixed(2);
    this.user.maxRoiStage1 = this.maxRoiStage1 = parseFloat(this.maxRoiStage1).toFixed(2);
    this.user.maxRoiStage2 = this.maxRoiStage2 = parseFloat(this.maxRoiStage2).toFixed(2);
    this.user.maxRoiStage3 = this.maxRoiStage3 = parseFloat(this.maxRoiStage3).toFixed(2);
    this.mainService
      .update_stop_loss_value({
        stoplossStage1: this.stoplossStage1,
        stoplossStage3: this.stoplossStage3,
        stoplossStage4: this.stoplossStage4,
        maxRoiStage1: this.maxRoiStage1,
        maxRoiStage2: this.maxRoiStage2,
        maxRoiStage3: this.maxRoiStage3,
        user_id: this.stoplossAccount.userId,
      })
      .subscribe(
        (res) => {
          if (res["success"]) {
            this.updateStages()
            this.toastrService.success(res["message"], "Success");
          } else {
            return false;
          }
        },
        (err) => {
          if (err.error.authenticated == false) {
            this.mainService.logout().subscribe(
              (res) => {
              },
              (err) => {}
            );
          }
          if (err.error.message) {
            this.toastrService.error(err["error"]["message"], "Error");
          } else {
            this.toastrService.error(
              "Unable to get response from Our Lira servers",
              "Error"
            );
          }
        }
      );
  }

  toggleDashboardTabs(val) {
    if (val === 0) {
      for (const account of this.accounts) {
        if (account.registrationType === 'IRA') {
          this.stoplossAccount = account;
        }
      }
      this.isDashboardAccount = true;
      this.isDashboardIraAccount = true;
      this.isDashboardNonIraAccount = false;
    } else {
      for (const account of this.accounts) {
        if (account.registrationType === 'Brokerage') {
          this.stoplossAccount = account;
        }
      }
      this.isDashboardAccount = false;
      this.isDashboardIraAccount = false;
      this.isDashboardNonIraAccount = true;
    }
  }

  removedChanged(index, account, value, check) {
    if (!check) {
      if (this.nonIraData[index].remain) {
        this.toastrService.error(
          `Please sell all owned shares of ${this.nonIraData[index].symbol} before removal`,
          "Error"
        );
        return;
      }
      if (this.nonIraData[index].id) {
        if (value === true) {
          // console.log('if')
          this.confirmation
            .confirm(
              `Please confirm your removal of ${this.nonIraData[index].symbol} `,
              {
                okButtonText: "Yes",
                cancelButtonText: "No",
                title: "Purchase",
              }
            )
            .subscribe((ans: any) => {
              if (ans) {
                // if ("resolved" in ans && ans.resolved) {
                // console.log('resolve')
                this.loaderService.start();
                this.update_remove(index, account, value, check);
                setTimeout(() => this.loaderService.stop(), 3000);
              }
            });
        } else {
          // console.log('else')
          this.loaderService.start();
          this.update_remove(index, account, value, check);
          setTimeout(() => this.loaderService.stop(), 3000);
        }
      }
    } else {
      if (this.iraData[index].remain) {
        this.toastrService.error(
          `Please sell all owned shares of ${this.iraData[index].symbol} before removal`,
          "Error"
        );
        return;
      }
      if (this.iraData[index].id) {
        if (value === true) {
          // console.log('if')
          this.confirmation
            .confirm(
              `Please confirm your removal of ${this.iraData[index].symbol} `,
              {
                okButtonText: "Yes",
                cancelButtonText: "No",
                title: "Purchase",
              }
            )
            .subscribe((ans: any) => {
              if (ans) {
                // if ("resolved" in ans && ans.resolved) {
                // console.log('resolve')
                this.loaderService.start();
                this.update_remove(index, account, value, check);
                setTimeout(() => this.loaderService.stop(), 3000);
              }
            });
        } else {
          // console.log('else')
          this.loaderService.start();
          this.update_remove(index, account, value, check);
          setTimeout(() => this.loaderService.stop(), 3000);
        }
      }
    }
  }

  update_dashboard(param, account, index, check): any {
    this.mainService.update_dashboard(param).subscribe(
      (res) => {
        if (res["success"]) {
          // if (account != null && index != null) {

          if (!check) {
            this.nonIraData.splice(index, 1);
            this.nonIraData = [...this.nonIraData];
          } else {
            this.iraData.splice(index, 1);
            this.iraData = [...this.iraData];
          }

          this.cdr.markForCheck();
          return true;
          // }
        } else {
          return false;
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  update_remove(index, account, value, check) {
    if (!check) {
      this.nonIraData[index].removeIt = value;
      this.nonIraData[index].remove_it = value;
      this.nonIraData[index].sell_it =
        !value && !this.nonIraData[index].liquidate ? value : true;
      this.nonIraData[index].sellIt =
        !value && !this.nonIraData[index].liquidate ? value : true;
      if (value) {
        this.nonIraData[index].buyIt = false;
        this.nonIraData[index].liquidateIt = false;
      }
      let param = {
        id: this.nonIraData[index].id,
        remove_it: this.nonIraData[index].remove_it,
        sell_it: this.nonIraData[index].sell_it,
      };

      if (this.nonIraData[index].remove_it) {
        param["buy_it"] = this.nonIraData[index].buy_it = false;
      }

      // console.log('params', param);

      const reponseOnDashboard = this.update_dashboard(
        param,
        null,
        null,
        check
      );
      // this.nonIraData.splice(index, 1);
    } else {
      this.iraData[index].removeIt = value;
      this.iraData[index].remove_it = value;
      this.iraData[index].sell_it =
        !value && !this.iraData[index].liquidate ? value : true;
      this.iraData[index].sellIt =
        !value && !this.iraData[index].liquidate ? value : true;
      if (value) {
        this.iraData[index].buyIt = false;
        this.iraData[index].liquidateIt = false;
      }
      let param = {
        id: this.iraData[index].id,
        remove_it: this.iraData[index].remove_it,
        sell_it: this.iraData[index].sell_it,
      };

      if (this.iraData[index].remove_it) {
        param["buy_it"] = this.iraData[index].buy_it = false;
      }

      // console.log('params', param);

      const reponseOnDashboard = this.update_dashboard(
        param,
        null,
        null,
        check
      );
      // this.iraData.splice(index, 1);
    }

    // console.log('reponseOnDashboard', reponseOnDashboard)
  }

  initRSI90Form(){
    this.rsi90Form = this.fb.group({
      rsi90DataRows: this.fb.array([])
    });
  }

  getRSI90Data(){
    this.loaderService.start();
    this.initRSI90Form();
    this.mainService.get_rsi_90_data().subscribe(
      (res) => {
      this.loaderService.stop();
      if (res["success"]) {
          res.data.forEach((data, _) => {
            this.rsi90DataRows.push(this.createRSI90FormGroup(data));
          });
        }
      },
      (err) => {
        this.loaderService.stop();
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe();
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }

  getPortfolios() {
      console.log("Getting portfolios")
      this.mainService.get_portfolios().subscribe(
      (res) => {
        if (res["success"]) {
          this.iraData = [];
          this.nonIraData = [];
          this.accounts = res["data"]["accounts"];
          this.tickers = res["data"]["tickers"];
          this.matrices = res["data"]['matrices'];
          for (var i = 0; i < this.accounts.length; i++) {
            for (var j = 0; j < this.tickers.length; j++) {
              if (this.accounts[i].registrationType == "IRA") {
                if (this.tickers[j].account_id == this.accounts[i].id) {
                  this.iraAccountId = this.accounts[i].id;
                  this.iraData.push(this.tickers[j]);
                }
              } else {
                if (this.tickers[j].account_id == this.accounts[i].id) {
                  this.nonIraAccountId = this.accounts[i].id;
                  this.nonIraData.push(this.tickers[j]);
                }
              }
            }
          }
          if (this.data[0] && this.data[0].registrationType == "IRA") {
            this.toggleDashboardTabs(0);
          } else {
            this.toggleDashboardTabs(1);
          }
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  connectSocket() {
    this.socketService.updates().subscribe((res) => {
      if (
        res["message"] == "TDA sockets closed" ||
        res["message"] == "Local sockets closed"
      ) {
        console.log("TDA sockets closed in portfolio");
        if (!this.modalOpenCheck) {
          this.modalRef = this.modalService.open(NgbdModalContent, {
            backdrop: "static",
            keyboard: false,
          });
          this.modalOpenCheck = true;
          if (res["message"] == "Local sockets closed") {
            this.modalRef.componentInstance.message =
              "Dashboard disconnected. Trading activities are still functional.";
          } else {
            this.modalRef.componentInstance.message =
              "Dashboard disconnected! Trading activities are NOT functional.";
          }
        }
      } else {
        if (this.modalOpenCheck) {
          this.modalRef.close();
          this.modalOpenCheck = false;
        }
        console.log("Socket response received in portfolio");
        // console.log("this data ======= ",this.data);
        this.rsiData = res["data"]["accounts"][0]["User"];
        // debugger;
        console.log(this.rsiData);
        if (this.rsiData.rsi90 >= 0) {
          this.form.controls["rsi90"].setValue(
            (Math.round(this.rsiData.rsi90 * 100) / 100).toFixed(2)
          );
        }
        if (this.rsiData.vix >= 0) {
          this.form.controls["vix"].setValue(
            (Math.round(this.rsiData.vix * 100) / 100).toFixed(2)
          );
        }
        if (this.rsiData.vixCurrent >= 0) {
          this.form.controls["vixCurrent"].setValue(
            (Math.round(this.rsiData.vixCurrent * 100) / 100).toFixed(2)
          );
        }

        if (this.rsiData.rsi90Low >= 0 && !this.allocationEditRsi) {
          this.form.controls["rsi90Low"].setValue(this.rsiData.rsi90Low + " %");
        }

        if (this.rsiData.rsi90Mid >= 0 && !this.allocationEditRsi) {
          this.form.controls["rsi90Mid"].setValue(
            (Math.round(this.rsiData.rsi90Mid * 100) / 100).toFixed(2)
          );
        }

        if (this.rsiData.rsi90High >= 0 && !this.allocationEditRsi) {
          this.form.controls["rsi90High"].setValue(
            this.rsiData.rsi90High + " %"
          );
        }
      }
    });
  }

  onSubmitRsi90Form() {
    if (this.rsi90Form.valid) {
      this.loaderService.start();
      const rsi90Rows = this.rsi90Form.get('rsi90DataRows') as FormArray;
      const submittedData = rsi90Rows.controls.map((group: FormGroup) => {
        // Here you can access each field using group.get('fieldName').value
        return {
          week: group.get('week').value,
          value: group.get('value').value,
          startDate: group.get('startDate').value,
          endDate: group.get('endDate').value
        };
      });
      this.mainService.update_rsi_90_data({data: submittedData}).subscribe(
        (res) => {
          this.loaderService.stop();
          if (res["success"]) {
          this.getRSI90Data();
          this.rsi90FormEdit = false;
          this.toastrService.success(
              "RSI 90 Data updated successfully",
              "Success"
            );

          }
        },
        (err) => {
          this.loaderService.stop();
          if (err.error.authenticated == false) {
            this.mainService.logout().subscribe();
          }
          if (err.error.message) {
            this.toastrService.error(err["error"]["message"], "Error");
          } else {
            this.toastrService.error(
              "Unable to get response from Our Lira servers",
              "Error"
            );
          }
        }
      );
    } else{
      this.toastrService.error(
        "Invalid RSI 90 Form values",
        "Error"
      );
    }
  }
  
  onSubmit(check?) {
    this.loaderService.start();
    if (
      !this.form.controls["shortAllocation"].value ||
      this.form.controls["shortAllocation"].value < 0
    ) {
      this.form.controls["shortAllocation"].setValue(0);
    }
    if (
      !this.form.controls["maximumSecurityAllocation"].value ||
      this.form.controls["maximumSecurityAllocation"].value < 0
    ) {
      this.form.controls["maximumSecurityAllocation"].setValue(0);
    }
    if (
      !this.form.controls["purchaseLong"].value ||
      this.form.controls["purchaseLong"].value < 0
    ) {
      this.form.controls["purchaseLong"].setValue(0);
    }
    if (
      !this.form.controls["purchaseShort"].value ||
      this.form.controls["purchaseShort"].value < 0
    ) {
      this.form.controls["purchaseShort"].setValue(0);
    }
    if (
      !this.form.controls["support"].value ||
      this.form.controls["support"].value < 0
    ) {
      this.form.controls["support"].setValue(0);
    }
    if (
      !this.form.controls["resistance"].value ||
      this.form.controls["resistance"].value < 0
    ) {
      this.form.controls["resistance"].setValue(0);
    }
    if (
      !this.form.controls["rsi90"].value ||
      this.form.controls["rsi90"].value < 0
    ) {
      this.form.controls["rsi90"].setValue(0);
    }
    if (
      !this.form.controls["rsi90Mid"].value ||
      this.form.controls["rsi90Mid"].value < 0
    ) {
      this.form.controls["rsi90Mid"].setValue(0);
    }
    if (
      !this.form.controls["rsi90Low"].value ||
      this.form.controls["rsi90Low"].value < 0
    ) {
      this.form.controls["rsi90Low"].setValue(0);
    }
    if (
      !this.form.controls["rsi90High"].value ||
      this.form.controls["rsi90High"].value < 0
    ) {
      this.form.controls["rsi90High"].setValue(0);
    }
    if (
      !this.form.controls["vix"].value ||
      this.form.controls["vix"].value < 0
    ) {
      this.form.controls["vix"].setValue(0);
    }
    if (
      !this.form.controls["vixCurrent"].value ||
      this.form.controls["vixCurrent"].value < 0
    ) {
      this.form.controls["vixCurrent"].setValue(0);
    }
    if (
      !this.form.controls["vixCurrent"].value ||
      this.form.controls["vixCurrent"].value < 0
    ) {
      this.form.controls["vixCurrent"].setValue(0);
    }
    if (!this.form.controls["buyInterval"].value.includes(":")) {
      if (Number(this.form.controls["buyInterval"].value) < 10) {
        this.form.controls["buyInterval"].setValue(
          "0" + this.form.controls["buyInterval"].value + ":00"
        );
      } else {
        this.form.controls["buyInterval"].setValue(
          this.form.controls["buyInterval"].value + ":00"
        );
      }
    }
    if (!this.form.controls["sellInterval"].value.includes(":")) {
      if (Number(this.form.controls["sellInterval"].value) < 10) {
        this.form.controls["sellInterval"].setValue(
          "0" + this.form.controls["sellInterval"].value + ":00"
        );
      } else {
        this.form.controls["sellInterval"].setValue(
          this.form.controls["sellInterval"].value + ":00"
        );
      }
    }
    console.log('asdf => ', check)
    let changed = false;
    if (check) {
      changed = true;
    } else {
      if (
        this.form.controls["shortAllocation"].value !=
          this.user.shortAllocation ||
        this.form.controls["maximumSecurityAllocation"].value !=
          this.user.maximumSecurityAllocation ||
        this.form.controls["purchaseLong"].value != this.user.purchaseLong ||
        this.form.controls["purchaseShort"].value != this.user.purchaseShort ||
        this.form.controls["buyInterval"].value != this.user.buyInterval ||
        this.form.controls["sellInterval"].value != this.user.sellInterval ||
        this.form.controls["support"].value != this.user.support ||
        this.form.controls["resistance"].value != this.user.resistance ||
        this.form.controls["rsi90"].value != this.user.rsi90 ||
        this.form.controls["rsi90Mid"].value != this.user.rsi90Mid ||
        this.form.controls["rsi90Low"].value != this.user.rsi90Low ||
        this.form.controls["rsi90High"].value != this.user.rsi90High ||
        this.form.controls["vix"].value != this.user.vix ||
        this.form.controls["vixCurrent"].value != this.user.vixCurrent
      ) {
        changed = true;
      }
    }
    if (changed) {
      let param = {
        shortAllocation: this.form.controls["shortAllocation"].value,
        maximumSecurityAllocation:
          this.form.controls["maximumSecurityAllocation"].value,
        support: this.form.controls["support"].value,
        resistance: this.form.controls["resistance"].value,
        buyInterval: this.form.controls["buyInterval"].value,
        sellInterval: this.form.controls["sellInterval"].value,
        purchaseLong: this.form.controls["purchaseLong"].value,
        purchaseShort: this.form.controls["purchaseShort"].value,
        rsi90: this.form.controls["rsi90"].value,
        rsi90Mid: this.form.controls["rsi90Mid"].value,
        rsi90Low: this.form.controls["rsi90Low"].value,
        rsi90High: this.form.controls["rsi90High"].value,
        vix: this.form.controls["vix"].value,
        vixCurrent: this.form.controls["vixCurrent"].value,
        applyNow: check ? true : false,
      };
      this.mainService.updateProfile(param).subscribe(
        (res) => {
          if (res && res["success"]) {
            this.user.shortAllocation =
              this.form.controls["shortAllocation"].value;
            this.user.maximumSecurityAllocation =
              this.form.controls["maximumSecurityAllocation"].value;
            this.user.purchaseLong = this.form.controls["purchaseLong"].value;
            this.user.purchaseShort = this.form.controls["purchaseShort"].value;
            this.user.buyInterval = this.form.controls["buyInterval"].value;
            this.user.sellInterval = this.form.controls["sellInterval"].value;
            this.user.support = this.form.controls["support"].value;
            this.user.resistance = this.form.controls["resistance"].value;
            this.user.rsi90 = this.form.controls["rsi90"].value;
            this.user.rsi90Mid = this.form.controls["rsi90Mid"].value;
            this.user.rsi90Low = this.form.controls["rsi90Low"].value;
            this.user.rsi90High = this.form.controls["rsi90High"].value;
            this.user.vix = this.form.controls["vix"].value;
            this.user.vixCurrent = this.form.controls["vixCurrent"].value;
            this.form.controls["shortAllocation"].setValue(
              this.user.shortAllocation.toFixed(2) + " %"
            );
            this.form.controls["maximumSecurityAllocation"].setValue(
              this.user.maximumSecurityAllocation.toFixed(2) + " %"
            );
            this.form.controls["purchaseLong"].setValue(
              (Math.round(this.user.purchaseLong * 100) / 100).toFixed(2) + " %"
            );
            this.form.controls["purchaseShort"].setValue(
              (Math.round(this.user.purchaseShort * 100) / 100).toFixed(2) +
                " %"
            );
            this.form.controls["rsi90"].setValue(
              (Math.round(this.user.rsi90 * 100) / 100).toFixed(2)
            );
            this.form.controls["rsi90Mid"].setValue(
              (Math.round(this.user.rsi90Mid * 100) / 100).toFixed(2)
            );
            this.form.controls["rsi90Low"].setValue(this.user.rsi90Low + " %");
            this.form.controls["rsi90High"].setValue(
              this.user.rsi90High + " %"
            );
            this.form.controls["vix"].setValue(
              (Math.round(this.user.vix * 100) / 100).toFixed(2)
            );
            this.form.controls["vixCurrent"].setValue(
              (Math.round(this.user.vixCurrent * 100) / 100).toFixed(2)
            );
            this.allocationEdit = false;
            this.allocationEditRsi = false;
            this.toastrService.success(res["message"], "Success");
          } else {
            this.toastrService.error(res["message"], "Error");
          }
        },
        (err) => {
          if (err.error.authenticated == false) {
            this.mainService.logout().subscribe(
              (res) => {
                //   if(res['status']){
                // }
              },
              (err) => {}
            );
            // this.liraService.disconnectSocket();
          }
          if (err.error.message) {
            this.toastrService.error(err["error"]["message"], "Error");
          } else {
            this.toastrService.error(
              "Unable to get response from Our Lira servers",
              "Error"
            );
          }
        }
      );
      setTimeout(() => this.loaderService.stop(), 3000);
    } else {
      this.form.controls["shortAllocation"].setValue(
        (
          Math.round(this.form.controls["shortAllocation"].value * 100) / 100
        ).toFixed(2) + " %"
      );
      this.form.controls["maximumSecurityAllocation"].setValue(
        (
          Math.round(
            this.form.controls["maximumSecurityAllocation"].value * 100
          ) / 100
        ).toFixed(2) + " %"
      );
      this.form.controls["purchaseLong"].setValue(
        (
          Math.round(this.form.controls["purchaseLong"].value * 100) / 100
        ).toFixed(2) + " %"
      );
      this.form.controls["purchaseShort"].setValue(
        (
          Math.round(this.form.controls["purchaseShort"].value * 100) / 100
        ).toFixed(2) + " %"
      );
      this.form.controls["rsi90"].setValue(
        (Math.round(this.form.controls["rsi90"].value * 100) / 100).toFixed(2)
      );
      this.form.controls["rsi90Mid"].setValue(
        (Math.round(this.form.controls["rsi90Mid"].value * 100) / 100).toFixed(
          2
        )
      );
      this.form.controls["rsi90Low"].setValue(
        this.form.controls["rsi90Low"].value + " %"
      );
      this.form.controls["rsi90High"].setValue(
        this.form.controls["rsi90High"].value + " %"
      );
      this.form.controls["vix"].setValue(
        (Math.round(this.form.controls["vix"].value * 100) / 100).toFixed(2)
      );
      this.form.controls["vixCurrent"].setValue(
        (
          Math.round(this.form.controls["vixCurrent"].value * 100) / 100
        ).toFixed(2)
      );
      this.allocationEdit = false;
      this.allocationEditRsi = false;
      this.loaderService.stop();
    }
  }
  getAccounts() {
    this.mainService.getAccounts().subscribe(
      (res) => {
        // console.log(res);
        if (res && res["success"]) {
          this.data = res["data"]["accounts"];
          for (let index in this.data) {
            this.data[index]["edit"] = false;
            this.data[index]["new_cash_reserve"] =
              this.data[index]["cash_reserve"];
          }
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
          // this.liraService.disconnectSocket();
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }

  toggleRSI90FormEdit(value) {
    this.rsi90FormEdit = value
  }

  changePortfolioBoolean(value, check) {
    if (check) {
      this.allocationEditRsi = value;
    } else {
      this.allocationEdit = value;
    }
    if (value) {
      this.form.controls["shortAllocation"].setValue(this.user.shortAllocation);
      this.form.controls["maximumSecurityAllocation"].setValue(
        this.user.maximumSecurityAllocation
      );
      this.form.controls["purchaseLong"].setValue(this.user.purchaseLong);
      this.form.controls["purchaseShort"].setValue(this.user.purchaseShort);
      this.form.controls["rsi90"].setValue(this.user.rsi90);
      this.form.controls["rsi90Mid"].setValue(this.user.rsi90Mid);
      this.form.controls["rsi90Low"].setValue(this.user.rsi90Low);
      this.form.controls["rsi90High"].setValue(this.user.rsi90High);
      this.form.controls["vix"].setValue(this.user.vix);
      this.form.controls["vixCurrent"].setValue(this.user.vixCurrent);
    } else {
      this.form.controls["shortAllocation"].setValue(
        (Math.round(this.user.shortAllocation * 100) / 100).toFixed(2) + " %"
      );
      this.form.controls["maximumSecurityAllocation"].setValue(
        (Math.round(this.user.maximumSecurityAllocation * 100) / 100).toFixed(
          2
        ) + " %"
      );
      this.form.controls["purchaseLong"].setValue(
        (Math.round(this.user.purchaseLong * 100) / 100).toFixed(2) + " %"
      );
      this.form.controls["purchaseShort"].setValue(
        (Math.round(this.user.purchaseShort * 100) / 100).toFixed(2) + " %"
      );
      this.form.controls["rsi90"].setValue(
        (Math.round(this.user.rsi90 * 100) / 100).toFixed(2)
      );
      this.form.controls["rsi90Mid"].setValue(
        (Math.round(this.user.rsi90Mid * 100) / 100).toFixed(2)
      );
      this.form.controls["rsi90Low"].setValue(this.user.rsi90Low + " %");
      this.form.controls["rsi90High"].setValue(this.user.rsi90High + " %");
      this.form.controls["vix"].setValue(
        (Math.round(this.user.vix * 100) / 100).toFixed(2)
      );
      this.form.controls["vixCurrent"].setValue(
        (Math.round(this.user.vixCurrent * 100) / 100).toFixed(2)
      );
      this.form.controls["buyInterval"].setValue(this.user.buyInterval);
      this.form.controls["sellInterval"].setValue(this.user.sellInterval);
      this.form.controls["support"].setValue(this.user.support);
      this.form.controls["resistance"].setValue(this.user.resistance);
    }
  }

  onNumberChange(rowIndex) {
    let min = 0;
    // netAccountValue
    // console.log(this.data[rowIndex]);
    if (this.data[rowIndex].new_cash_reserve < min) {
      this.data[rowIndex].new_cash_reserve = min;
    }
    // if (this.data[rowIndex].new_cash_reserve > this.data[rowIndex].netAccountValue) {
    //   this.data[rowIndex].new_cash_reserve = this.data[rowIndex].netAccountValue;
    // }
  }
  edit(account) {
    account.edit = true;
  }
  save(account) {
    // console.log(account);
    let param = {
      _id: account.id,
      cash_reserve: account.new_cash_reserve,
    };

    this.mainService.update_account(param).subscribe(
      (res) => {
        if (res && res["success"]) {
          this.data = res["data"]["accounts"];
          for (let index in this.data) {
            this.data[index]["edit"] = false;
            this.data[index]["new_cash_reserve"] =
              this.data[index]["cash_reserve"];
          }
        } else {
          this.toastrService.error(res["message"], "Error");
        }
      },
      (err) => {
        if (err.error.authenticated == false) {
          this.mainService.logout().subscribe(
            (res) => {
              //   if(res['status']){
              // }
            },
            (err) => {}
          );
          // this.liraService.disconnectSocket();
        }
        if (err.error.message) {
          this.toastrService.error(err["error"]["message"], "Error");
        } else {
          this.toastrService.error(
            "Unable to get response from Our Lira servers",
            "Error"
          );
        }
      }
    );
  }
  cancel(account) {
    account.edit = false;
    account.new_cash_reserve = account.cash_reserve;
  }

  ngOnDestroy() {
    // window.removeEventListener('scroll', this.onScroll, true);
    this.socketService.stopTickers();
  }
}
