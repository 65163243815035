
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgLoggerModule, Level } from '@nsalaun/ng-logger';
import {
    NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
    PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule
} from 'ngx-ui-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { JwtModule, JwtHelperService, JwtModuleOptions } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { MainService } from './shared/services/main.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
// import { CustomOption } from "./shared/toastr/custom-option";
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Config } from './config/config';
import { RecaptchaModule } from 'ng-recaptcha';
// import { JasperoConfirmationsModule } from "@jaspero/ng-confirmations";
import { NgxCoolDialogsModule, NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { LocalStorage } from './shared/libs/localstorage';
import { CustodianAuthGuardService } from './auth/custodian-auth-guard.service';
// import { WebsocketService } from './shared/services/websocket.service';
// import { OurliraService } from './shared/services/ourlira.service';
import { SocketService } from './shared/services/socket.service';
import { NgxLoaderService } from './shared/services/ui-loader.service';
// import { NgxLoadingModule } from 'ngx-loading';
// import {DragulaExtendedDirective} from 'dragula-extended.directive.ts';

export function gettoken() {
    return localStorage.getItem('token');
};

const jwtConf: JwtModuleOptions = {
    config: {
        tokenGetter: gettoken,
        // whitelistedDomains: ['localhost:4200']
    }
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#OOACC1',
    bgsOpacity: 0,
    bgsPosition: POSITION.centerCenter,
    bgsSize: 100,
    bgsType: SPINNER.rectangleBounce,
    blur: 0,
    fgsColor: '#00ACC1',
    fgsPosition: POSITION.centerCenter,
    fgsSize: 60,
    fgsType: SPINNER.foldingCube,
    pbColor: '#00ACC1',
    pbDirection: PB_DIRECTION.leftToRight,
    pbThickness: 5,
    masterLoaderId: "master",
};


@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        // DragulaExtendedDirective
    ],
    imports: [
        RecaptchaModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        // DragulaModule,
        FormsModule,
        AppRoutingModule,
        SharedModule,
        NgLoggerModule.forRoot(Level.LOG),
        JwtModule.forRoot(jwtConf),
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }), // ToastrModule added
        NgbModule,
        ModalModule.forRoot(),
        // JasperoConfirmationsModule.forRoot(),
        NgxCoolDialogsModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
        // NgxUiLoaderRouterModule.forRoot({ }),
        // NgxUiLoaderHttpModule.forRoot({ exclude: ['http://localhost:3000/api/logout', 'http://localhost:3000/api/login'] }),
        // NgxLoadingModule.forRoot({})
    ],
    providers: [
      // DragulaService,
        AuthService,
        AuthGuardService,
        CustodianAuthGuardService,
        JwtHelperService,
        MainService,
        Config,
        LocalStorage,
        // WebsocketService,
        SocketService,
        NgxLoaderService,
        NgxCoolDialogsService
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
