import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, CanActivate } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CustodianAuthGuardService as CustodianAuthGuard } from './auth/custodian-auth-guard.service';
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: "full"
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [CustodianAuthGuard]   },
  // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  // { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}