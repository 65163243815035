import { of as observableOf, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { Component, OnInit, NgZone, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
// import { SocketService } from '../../services/socket.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { MainService } from '../../services/main.service';
@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  // providers:[SocketService]
})
export class ProductComponent implements OnInit {
  data :any= [];
  realData:any=[]
  iraAccountId:0
  nonIraAccountId:0
  instrument:any=[]
  iraData:any=[]
  nonIraData:any=[]
  form: FormGroup;
  searchText:any=''
  searchedData :any=[];
  selectedValue:any='Long';
  @Input() user;
  isDashboard=true;
  isInstruments=false;
  isDashboardAccount=true;
  isDashboardNonIraAccount=true;
  isDashboardIraAccount=false;
  keyword = 'symbol'
  commissionEdit: Boolean;
	search_ticker = {
		assetType: "",
		cusip: "",
		description: "",
		exchange: "",
		symbol: "",
	};;
  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loaderService: NgxUiLoaderService
  ) {}
    ngOnInit() {
      this.getAccounts();
      // this.getPortfolios()

      this.form = this.fb.group({
        "commission": [(this.user && this.user.commission && this.user.commission>=0) ? this.user.commission : 0, [Validators.required]],
        "sellCommission": [(this.user && this.user.sellCommission && this.user.sellCommission>=0) ? this.user.sellCommission : 0, [Validators.required]],
      });
			this.toggleTabs(0);
    }

		searching = false;
		searchFailed = false;

		getQuote = (text$: Observable<string>) => text$.pipe(
			debounceTime(1000),
			distinctUntilChanged(),
			tap(() => this.searching = true),
			switchMap(term => {
				this.loaderService.start();
				const serviceResponse = this.mainService.get_search_instruments(term).pipe(
					tap(() => this.searchFailed = false),
					catchError((err) => {
						this.searchFailed = true;
						if (err.error.authenticated == false) {
							this.mainService.logout().subscribe(
								res=>{
								//   if(res['status']){
										// }
								},
								err => {
								});
						}
						if(err.error.message){
							this.toastrService.error(err['error']['message'], "Error");
						} else{
							this.toastrService.error('Unable to get response from Our Lira servers', "Error");
						}
						return observableOf([]);
					}));
				setTimeout(() => this.loaderService.stop(), 3000);
				return serviceResponse;
			}),
			tap(() => this.searching = false));
	  hosFormatter = (x: { symbol: string }) => x.symbol;
    ngOnChanges() {

      if (this.user) {
        this.form = this.fb.group({
          "fullName": [this.user['fullName'], [Validators.required]],
          "email": [this.user['email'], [Validators.required]],
        });
      }
    }
    onSubmit() {
      this.loaderService.start();
      if(!this.form.controls['commission'].value ||(this.form.controls['commission'].value < 0)){
        this.form.controls['commission'].setValue(0);
      }
      if(!this.form.controls['sellCommission'].value ||(this.form.controls['sellCommission'].value < 0)){
        this.form.controls['sellCommission'].setValue(0);
      }
      if(this.form.controls['commission'].value!=this.user.commission || this.form.controls['sellCommission'].value!=this.user.sellCommission){
        let param = {
          'commission': this.form.controls['commission'].value,
          'sellCommission': this.form.controls['sellCommission'].value,
        };
        this.mainService.updateProfile(param).subscribe(
          res => {
            if (res && res['success']) {
              this.user.commission = this.form.controls['commission'].value;
              this.user.sellCommission = this.form.controls['sellCommission'].value;
              this.commissionEdit = false;
              this.toastrService.success(res['message'], "Success");
            }
            else {
              this.toastrService.error(res['message'], "Error");
            }
          }, err => {
            if (err.error.authenticated == false) {
              this.mainService.logout().subscribe(
                res=>{
                  //   if(res['status']){
                  // }
                },
                err => {
                });
                // this.liraService.disconnectSocket();
              }
              if(err.error.message){
                this.toastrService.error(err['error']['message'], "Error");
              } else{
                this.toastrService.error('Unable to get response from Our Lira servers', "Error");
              }
            }
          );
          setTimeout(() => this.loaderService.stop(), 3000);
        } else{
          this.commissionEdit = false;
          this.loaderService.stop()
        }
      }
      getAccounts() {
        this.mainService.getAccounts().subscribe(
          res => {
            // console.log(res);
            if (res && res['success']) {
              this.data = res['data']['accounts'];
              for (let index in this.data) {
                this.data[index]['edit'] = false;
                this.data[index]['new_cash_reserve'] = this.data[index]['cash_reserve'];
              }
            }
            else {
              this.toastrService.error(res['message'], "Error");
            }
          }, err => {
            if (err.error.authenticated == false) {
              this.mainService.logout().subscribe(
                res=>{
                  //   if(res['status']){
                  // }
                },
                err => {
                });
                // this.liraService.disconnectSocket();
              }
              if(err.error.message){
                this.toastrService.error(err['error']['message'], "Error");
              } else{
                this.toastrService.error('Unable to get response from Our Lira servers', "Error");
              }
            }
          );
        }
        changeCommissionBoolean(value) {
          this.commissionEdit = value;
        }

        onNumberChange(rowIndex) {
          let min = 0;
          // netAccountValue
          // console.log(this.data[rowIndex]);
          if (this.data[rowIndex].new_cash_reserve < min) {
            this.data[rowIndex].new_cash_reserve = min;
          }
          // if (this.data[rowIndex].new_cash_reserve > this.data[rowIndex].netAccountValue) {
          //   this.data[rowIndex].new_cash_reserve = this.data[rowIndex].netAccountValue;
          // }
        }
        edit(account) {
          account.edit = true;
        }
        save(account) {
          // console.log(account);
          let param = {
            _id: account.id,
            cash_reserve: account.new_cash_reserve
          };

          this.mainService.update_account(param).subscribe(
            res => {
              if (res && res['success']) {
                this.data = res['data']['accounts'];
                for (let index in this.data) {
                  this.data[index]['edit'] = false;
                  this.data[index]['new_cash_reserve'] = this.data[index]['cash_reserve'];
                }
              }
              else {
                this.toastrService.error(res['message'], "Error");
              }
            }, err => {
              if (err.error.authenticated == false) {
                this.mainService.logout().subscribe(
                  res=>{
                    //   if(res['status']){
                    // }
                  },
                  err => {
                  });
                  // this.liraService.disconnectSocket();
                }
                if(err.error.message){
                  this.toastrService.error(err['error']['message'], "Error");
                } else{
                  this.toastrService.error('Unable to get response from Our Lira servers', "Error");
                }
              }
            );

          }
          cancel(account) {
            account.edit = false;
            account.new_cash_reserve = account.cash_reserve;
          }
          getInstruments()
          {
              this.mainService.get_instruments().subscribe(
                res => {
                  if(res['status']==true){
                    this.instrument=res['data']
                    this.instrument.push({
                      "id":-1,
                      "cusip":"",
                      "symbol":"",
                      "description":"",
                      "exchange":"",
                      "position":"",
                      "assetType":"",

                    })
                  }

                }, err => {
                  if (err.error.authenticated == false) {
                    this.mainService.logout().subscribe(
                      res=>{
                        //   if(res['status']){
                        // }
                      },
                      err => {
                      });
                    }
                    if(err.error.message){
                      this.toastrService.error(err['error']['message'], "Error");
                    } else{
                      this.toastrService.error('Unable to get response from Our Lira servers', "Error");
                    }
                  })
                }
                deleteInstruments(id)
                {
                  this.mainService.delete_instruments(id).subscribe(
                    res => {
                      if(res['status']==true){
                        this.toastrService.success(res['message'], "Success");
                        this.getInstruments()
                      }

                    }, err => {
                      if (err.error.authenticated == false) {
                        this.mainService.logout().subscribe(
                          res=>{
                            //   if(res['status']){
                            // }
                          },
                          err => {
                          });
                        }
                        if(err.error.message){
                          this.toastrService.error(err['error']['message'], "Error");
                        } else{
                          this.toastrService.error('Unable to get response from Our Lira servers', "Error");
                        }
                      })
                    }
                    toggleTabs(val)
                    {

                      if(val==0)
                      {
												this.isDashboardIraAccount=false
												this.isDashboardNonIraAccount=false
												this.isDashboard=false
												this.isInstruments=true
												this.getInstruments()
                      }
                    }
                    toggleDashboardTabs(val)
                    {
                      if(val==0)
                      {
                        this.isDashboardAccount=true
                        this.isDashboardIraAccount=true
                        this.isDashboardNonIraAccount=false
                      }
                      else{
                        this.isDashboardAccount=false
                        this.isDashboardIraAccount=false
                        this.isDashboardNonIraAccount=true
                      }
                    }
                    searchData(val)
                    {
                      if(val!='')
                      {
                        this.mainService.get_search_instruments(val).subscribe(
                          res => {
                            this.searchedData=[]
                            this.realData=[]
                            if(res['status']==true){
                              this.searchedData.push(res['data'])
                              this.realData=JSON.parse(JSON.stringify(this.searchedData))
                            }
                          }
                          , err => {
                            if (err.error.authenticated == false) {
                              this.mainService.logout().subscribe(
                                res=>{
                                  //   if(res['status']){
                                  // }
                                },
                                err => {
                                });
                              }
                              if(err.error.message){
                                this.toastrService.error(err['error']['message'], "Error");
                              } else{
                                this.toastrService.error('Unable to get response from Our Lira servers', "Error");
                              }
                            })
                          }

                        }
                        onFocused(e){}
                        onCLear(e){
                          this.realData=[]
                        }
                        selectEvent(e)
                        {
                          for(var i=0;i<this.instrument.length;i++)
                          {
                            if(this.instrument[i].id==-1)
                            {
                              this.instrument[i]["cusip"]=this.search_ticker.cusip
                              this.instrument[i]["description"]=this.search_ticker.description
                              this.instrument[i]["exchange"]=this.search_ticker.exchange
                              this.instrument[i]["position"]=this.selectedValue
                              this.instrument[i]["assetType"]=this.search_ticker.assetType
                              break;
                            }
                          }
                          this.instrument=[...this.instrument]
                        }
                        onSelectedChange(val)
                        {
                          this.selectedValue=val
                        }
                        addInstruments()
                        {
                          let obj={
                            instrument:{
                              "cusip":this.search_ticker.cusip,
                              "symbol":this.search_ticker.symbol,
                              "description":this.search_ticker.description,
                              "exchange":this.search_ticker.exchange,
                              "position":this.selectedValue,
                              "assetType":this.search_ticker.assetType,
                            }
                          }
                          this.mainService.add_instruments(obj).subscribe(
                            res => {
                              this.searchedData=[]
                              this.realData=[]
                              if(res['status']==true){
                                this.toastrService.success(res['message'], "Success");
                                this.getInstruments()
                              }
                            }
                            , err => {
                              if (err.error.authenticated == false) {
                                this.mainService.logout().subscribe(
                                  res=>{
                                    //   if(res['status']){
                                    // }
                                  },
                                  err => {
                                  });
                                }
                                if(err.error.message){
                                  this.toastrService.error(err['error']['message'], "Error");
                                } else{
                                  this.toastrService.error('Unable to get response from Our Lira servers', "Error");
                                }
                              })
                            }

                          }
