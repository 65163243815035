import { Routes, RouterModule } from '@angular/router';
//
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },

  {
    path: 'securities',
    loadChildren: './securities/securities.module#SecuritiesModule'
  },
  {
    path: 'orders',
    loadChildren: './orderlogs/orderlogs.module#OrderlogsModule'
    // loadChildren: () => import('./orderlogs/orderlogs.module').then(m => m.OrderlogsModule)
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsModule'
    // loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
];
