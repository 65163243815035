import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {

    public options = {
        duration: 3000
    }
    now: Date = new Date();
    routeSubscribe;
    page: string = 'Dashboard';
    userDetail: any;
    userName: any = 'Admin';
    constructor(
        public router: Router) {
    }
    ngOnInit() {
    }
}
