import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Alert!</h4>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
  `
})
export class NgbdModalContent {
  public message = 'Default message';

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'ngbd-modal-component',
  templateUrl: './alertModal-component.html'
})
export class NgbdModalComponent {
  constructor(private modalService: NgbModal) {}

  open() {
    const modalRef = this.modalService.open(NgbdModalContent, {
                backdrop: 'static',
                keyboard: false
              });
  }
}
