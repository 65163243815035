// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiUrl: "https://api.ourlira.com",
  // apiUrl: "http://localhost:3001",
  // apiUrl: "http://192.168.1.158:3001"
  // apiUrl: "https://demo.ourlira.com"
  // apiUrl: "https://apps.webarthub.com:3002"
  // apiUrl: "https://ourlira.serveo.net"
  // apiUrl: "https://demo.ourlira.com"
  // apiUrl: "https://lira.serveo.net"
};
