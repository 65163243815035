import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage } from '../shared/libs/localstorage';
// import {Logger} from '@nsalaun/ng-logger';
@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService,
    private localStorage: LocalStorage,
    // private logger: Logger
    ) {}

  public isAuthenticated(): boolean {
    const token = this.localStorage.get('token');
    console.log('isAuthenticated', token);
    if(token) {
      // console.log("auth service token = ", true);
      return true;
      // return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }
  public isCustodianAuthenticated() {
    const custodianAuthenticated = this.localStorage.get('custodianAuthenticated');
    console.log('custodianAuthenticated', custodianAuthenticated);
    // console.log("custodianAuthenticated",custodianAuthenticated);
    // console.log(custodianAuthenticated=='true');
    if(custodianAuthenticated=='true') {
      return true;
    }
    return false;
  }
}
