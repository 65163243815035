import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HeaderComponent } from "./header/header.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { MainService } from './services/main.service';
import { SocketService } from './services/socket.service';
import { ProfileComponent, AccountsComponent, ProductComponent, PortfolioComponent } from './components';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
    exports: [
        CommonModule,
        HeaderComponent,
        NgbModule,
        ProfileComponent,
        AccountsComponent,
        ProductComponent,
        PortfolioComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        AutocompleteLibModule,
        DragDropModule
    ],
    declarations: [
        HeaderComponent,
        ProfileComponent,
        AccountsComponent,
        ProductComponent,
        PortfolioComponent
    ],
    providers: [
        MainService,
        SocketService,
        CookieService
    ]
})
export class SharedModule { }
