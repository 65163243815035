import { Injectable } from "@angular/core";
import * as io from 'socket.io-client';
import {Observable} from 'rxjs';
import * as Rx from "rxjs/Rx";
import { Config } from '../../config/config';
import { LocalStorage } from '../libs/localstorage';
import { Logger } from '@nsalaun/ng-logger';

@Injectable()
export class SocketService {

    get_hdr;
    private socket;
    constructor(
        private config: Config,
        private localStorage:LocalStorage,
        private logger: Logger) {
        this.socket= io(this.config.getConfig('apiUrl'), {
          query: {
            'secure':true,
            'token':this.localStorage.get('token'),
            'fromFrontend': true,
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionAttempts': 9999,
          },
        });
        this.socket.on('connect', ()=>{
          console.log("SOCKETTTTTTTTTTTTTTTTTTTTT ==== ",this.socket.id);
        });

    }
    updates(){
        this.socket.connect();
        this.emitGetPortfolio();
        let observable = new Observable<{message:String}>(observer=>{
            this.socket.on("updates", (data)=>{
              console.log('ewqr', data)
                // this.logger.log(data);
                observer.next(data);
                //debugger;
            });
            this.socket.on('reconnect',function(number){
              console.log("Socket reconnected:--------------- ", number);
              // self.socket.connect();
              this.socket.emit('get_ticker', {token:self.localStorage.get('token')});

              // console.log("Socket :--------------- ", self.socket);
              // this.socket= io(this.config.getConfig('apiUrl'), {secure:true, token:this.localStorage.get('token')});
              // socket.emit('get_ticker', {token:this.localStorage.get('token')});

            })
            this.socket.on('disconnect',function(message){
              console.log("Socket disconnected:--------------- ", message);

              // this.socket= io(this.config.getConfig('apiUrl'), {secure:true, token:this.localStorage.get('token')});
              // socket.emit('get_ticker', {token:this.localStorage.get('token')});

            })
            return()=>{this.socket.disconnect(); this.logger.log("disconnected!");}
        });
        return observable;
    }
    
    emitGetPortfolio(){
      this.socket.emit('get_ticker', {token:this.localStorage.get('token')});
    }
   
    getDashboard(){
        this.socket.emit('get_ticker', {token:this.localStorage.get('token')});
    }
    stopTickers(){
        console.log("Disconnecting from socket: ", this.socket);
        this.socket.disconnect();
    }

    disconnectSocket(){
        if(this.socket){
            this.logger.log("Disconnecting...");
            this.logger.log(this.socket.disconnect());
        }
    }
}
